import React from 'react';
import { QUERY_PARAM_STANDARD_PLAN_ONLY } from 'src/pages/constants';

export const RecommendedPlanDescription = (props: {
  selectedPlan: any;
  styles: any;
}) => {
  const { selectedPlan, styles } = props;
  return (
    <ul className={styles.programDescription}>
      <li>
        1 x full-day, personalised meal plan provided weekly from our database
        of 7000+ delicious recipes
      </li>
      <li>Scientifically calculated daily targets catered to your goals</li>
      {!!selectedPlan.includes('Premium') && (
        <li>Personal 1:1 coaching with an expert</li>
      )}
      <li>Meal swaps (Australia only)</li>
      <li>
        Weekly check-ins with a progress tracker , and chat support to maintain
        motivation and accountability
      </li>
      <li>
        Food diary, water tracker, grocery list, educational guides and much
        more
      </li>
      <li>Lifetime access to private Equalution Facebook community</li>
      {!!selectedPlan.includes('Standard') && (
        <div className={styles.p1}>
          Note: the standard subscription is only available in Australia.
        </div>
      )}
    </ul>
  );
};

export const DefaultPlanDescription = (props: {
  planRequest: any;
  styles: any;
}) => {
  const { planRequest, styles } = props;
  return (
    <ul className={styles.programDescription}>
      <li>
        1 x full-day, personalised meal plan provided weekly from our database
        of 7000+ delicious recipes
      </li>
      <li>Scientifically calculated daily targets catered to your goals</li>
      <li>Unlimited meal swaps (Australian Clients)</li>
      {planRequest === QUERY_PARAM_STANDARD_PLAN_ONLY ? null : (
        <li>1:1 coaching with our team of nutritionists on our premium plan</li>
      )}
      <li>
        Weekly check-ins with a progress tracker{' '}
        {planRequest === QUERY_PARAM_STANDARD_PLAN_ONLY
          ? null
          : ', and chat support to maintain motivation and accountability'}
      </li>
      <li>
        Food diary, water tracker, grocery list, educational guides and much
        more
      </li>
      <li>Lifetime access to private Equalution Facebook community</li>
      <div className={styles.p1}>
        Note: Our premium plan has an 8-week minimum subscription term. It's the
        optimal time for our nutrition coaches to guide you.
      </div>
    </ul>
  );
};
