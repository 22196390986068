import React, { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import ReinforcerWeightGraph from 'src/assets/images/reinforcer-weight-graph.png';

interface ReinforcerWeightGoalProps {
  buttonClick: () => void;
  buttonText: string;
}

export const ReinforcerWeightGoal: FC<ReinforcerWeightGoalProps> = props => {
  const { buttonClick, buttonText } = props;
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1,
        }}>
        <Box
          sx={{
            width: '100%',
            maxWidth: '420px',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <Box
            sx={{
              display: 'flex',
              flex: { xs: 3, md: 1 },
              flexDirection: 'column',
              alignItems: { xs: 'flex-start', md: 'center' },
              justifyContent: 'space-between',
              padding: '20px',
            }}>
            <Typography
              sx={{
                fontSize: '22px',
                fontWeight: '500',
                marginTop: 4,
                color: 'white',
                marginBottom: 4,
              }}>
              By consistently following the Equalution program, we expect a 300g
              - 800g loss per week. We also embrace non-scale victories on your
              journey!
            </Typography>
            <img
              src={ReinforcerWeightGraph}
              alt="graph"
              style={{ margin: '10px 0px', width: '90%' }}
            />
            <Button
              variant="contained"
              onClick={buttonClick}
              size="large"
              sx={{
                textTransform: 'none',
                borderRadius: '100px',
                marginTop: 4,
                width: '330px',
                height: '55px',
                alignSelf: 'center',
                marginBottom: 2,
              }}>
              {buttonText}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
