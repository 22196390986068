import React, { useContext } from 'react';
import QuizPageWrapper from '../QuizPageWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { Box } from '@mui/material';

import { OptionButton } from 'src/components/option-button/OptionButton';
import { HEARD_ABOUT_EQ, INTEREST_ARR } from 'src/pages/constants';
import { updateQuizData } from 'src/store/quiz';
import { useQuizTracking } from '../../hooks';
import { submitOnboardingQuiz } from 'src/services/quiz-service';
import { ToastContext } from 'src/components/toast/toast';
import { CircleButton } from 'src/components/circle-button/CircleButton';
import { QuizQuestionersRequest } from '../../types';

const LocatedStep = () => {
  const { quizData } = useSelector((state: IAppState) => state.quiz);
  const user = useSelector((state: IAppState) => state.auth?.user);
  const dispatch = useDispatch();
  const { addToast } = useContext(ToastContext);
  const trackEvent = useQuizTracking();

  const handleClick = async (val: string) => {
    trackEvent('Country', val);

    if (quizData.isReturning || user?.status === 'INACTIVE') {
      let payloadObj: QuizQuestionersRequest = {
        FName: quizData?.FName,
        LName: quizData?.LName,
        email: quizData?.email,
        goal_id: quizData.goal_id,
        target_weight: quizData?.target_weight || 0,
        problem_hitting_goal: quizData.problem_hitting_goal,
        low_intensity_days: quizData.low_intensity_days,
        high_intensity_days: quizData.high_intensity_days,
        activity_level: quizData.activity_level,
        sleep_hours: quizData.sleep_hours,
        water_intake: quizData.water_intake,
        stress_level: quizData.stress_level,
        goal_adherence_challenges: quizData.goal_adherence_challenges,
        meal_adherence_challenges: quizData.meal_adherence_challenges, // meal challenges
        weight: quizData.weight,
        interests: quizData.interests,
        located: val,
        heard_about_eq: quizData.heard_about_eq,
      };
      if (quizData?.gender) {
        payloadObj['gender'] = quizData.gender.toUpperCase(); // uppercase gender;
      }
      const submitResponse = await submitOnboardingQuiz(payloadObj);
      if (submitResponse.status === 200) {
        addToast('Updated successfully.', 'success', 5000);
        dispatch(
          updateQuizData({
            located: val,
            currentStep: 30,
          })
        );
      } else {
        addToast('Something went wrong. Please try again.', 'danger', 5000);
      }
    } else {
      dispatch(
        updateQuizData({
          located: val,
          currentStep: 28,
        })
      );
    }
  };

  return (
    <Box sx={{ marginTop: '10px' }}>
      <OptionButton
        title="Australia"
        isSelected={quizData.located === 'Australia'}
        onPress={() => handleClick('Australia')}
      />
      <OptionButton
        title="New Zealand"
        isSelected={quizData.located === 'New Zealand'}
        onPress={() => handleClick('New Zealand')}
      />
      <OptionButton
        title="United Kingdom"
        isSelected={quizData.located === 'United Kingdom'}
        onPress={() => handleClick('United Kingdom')}
      />
      <OptionButton
        title="Europe"
        isSelected={quizData.located === 'Europe'}
        onPress={() => handleClick('Europe')}
      />
      <OptionButton
        title="United States"
        isSelected={quizData.located === 'United States'}
        onPress={() => handleClick('United States')}
      />
      <OptionButton
        title="Asia"
        isSelected={quizData.located === 'Asia'}
        onPress={() => handleClick('Asia')}
      />
      <OptionButton
        title="Middle East"
        isSelected={quizData.located === 'Middle East'}
        onPress={() => handleClick('Middle East')}
      />
      <OptionButton
        title="None of the above"
        isSelected={quizData.located === 'None of the above'}
        onPress={() => handleClick('None of the above')}
      />
    </Box>
  );
};
const InterestStep = () => {
  const dispatch = useDispatch();
  const trackEvent = useQuizTracking();

  const [interestArr, setInterestArr] = React.useState<Array<number>>([]);

  const handleClick = (value: number) => {
    const interestArrCopy = [...interestArr];
    if (!interestArrCopy.includes(value)) {
      interestArrCopy.push(value);
    } else {
      interestArrCopy.splice(interestArrCopy.indexOf(value), 1); //deleting
    }
    setInterestArr(interestArrCopy);
  };
  const handleSubmit = () => {
    dispatch(
      updateQuizData({
        interests: interestArr,
        currentStep: 27,
      })
    );
  };
  return (
    <Box sx={{ marginTop: '10px' }}>
      <OptionButton
        title="Meal deliveries"
        isSelected={interestArr.includes(INTEREST_ARR.MEAL_DELIVERIES)}
        onPress={() => {
          trackEvent('Interest', 'Boredom / late night snacking');
          handleClick(INTEREST_ARR.MEAL_DELIVERIES);
        }}
      />
      <OptionButton
        title="Online grocery shopping integration"
        isSelected={interestArr.includes(
          INTEREST_ARR.ONLINE_GROCERY_SHOPPING_INTEGRATION
        )}
        onPress={() => {
          trackEvent('Interest', 'Online grocery shopping integration');
          handleClick(INTEREST_ARR.ONLINE_GROCERY_SHOPPING_INTEGRATION);
        }}
      />
      <OptionButton
        title="Wearable integration"
        isSelected={interestArr.includes(INTEREST_ARR.WEARABLE_INTEGRATION)}
        onPress={() => {
          trackEvent('Interest', 'Wearable integration');
          handleClick(INTEREST_ARR.WEARABLE_INTEGRATION);
        }}
      />
      {/* <OptionButton
        title="Access to recipe library"
        isSelected={interestArr.includes(INTEREST_ARR.ACCESS_TO_RECIPE_LIBRARY)}
        onPress={() => {
          trackEvent('Interest', 'Access to recipe library');
          handleClick(INTEREST_ARR.ACCESS_TO_RECIPE_LIBRARY);
        }}
      /> */}
      <OptionButton
        title="Accountability partners"
        isSelected={interestArr.includes(INTEREST_ARR.ACCOUNTABILITY_PARTNERS)}
        onPress={() => {
          trackEvent('Interest', 'Accountability partners');
          handleClick(INTEREST_ARR.ACCOUNTABILITY_PARTNERS);
        }}
      />
      <OptionButton
        title="Local meetup & events"
        isSelected={interestArr.includes(INTEREST_ARR.LOCAL_MEETUP_AND_EVENTS)}
        onPress={() => {
          trackEvent('Interest', 'Local meetup & events');
          handleClick(INTEREST_ARR.LOCAL_MEETUP_AND_EVENTS);
        }}
      />
      <OptionButton
        title="Supplement products"
        isSelected={interestArr.includes(INTEREST_ARR.SUPPLEMENT_PRODUCTS)}
        onPress={() => {
          trackEvent('Interest', 'Supplement products');
          handleClick(INTEREST_ARR.SUPPLEMENT_PRODUCTS);
        }}
      />
      <OptionButton
        title="Ozempic"
        isSelected={interestArr.includes(INTEREST_ARR.OZEMPIC)}
        onPress={() => {
          trackEvent('Interest', 'Ozempic');
          handleClick(INTEREST_ARR.OZEMPIC);
        }}
      />
      <OptionButton
        title="Nutrition for sleep / energy / mood "
        isSelected={interestArr.includes(
          INTEREST_ARR.NUTRITION_FOR_SLEEP_ENERGY_MOOD
        )}
        onPress={() => {
          trackEvent('Interest', 'Nutrition for sleep / energy / mood ');
          handleClick(INTEREST_ARR.NUTRITION_FOR_SLEEP_ENERGY_MOOD);
        }}
      />
      <OptionButton
        title="None of the above"
        isSelected={interestArr.includes(INTEREST_ARR.NONE)}
        onPress={() => {
          trackEvent('Interest', 'None of the above');
          handleClick(INTEREST_ARR.NONE);
        }}
      />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'flex-end',
          justifyContent: 'end',
        }}>
        <CircleButton
          onPress={handleSubmit}
          disabled={interestArr.length === 0}
        />
      </Box>
    </Box>
  );
};
const HeardAboutStep = () => {
  const { quizData } = useSelector((state: IAppState) => state.quiz);
  const dispatch = useDispatch();
  const trackEvent = useQuizTracking();

  const handleClick = (val: number) =>
    dispatch(updateQuizData({ heard_about_eq: val, currentStep: 29 }));

  return (
    <Box sx={{ marginTop: '10px' }}>
      <OptionButton
        title="Friend or family member"
        isSelected={quizData.heard_about_eq === HEARD_ABOUT_EQ.FRIEND_FAMILY}
        onPress={() => {
          trackEvent('HearAboutUs', 'Friend or family member');
          handleClick(HEARD_ABOUT_EQ.FRIEND_FAMILY);
        }}
      />
      <OptionButton
        title="Instagram"
        isSelected={quizData.heard_about_eq === HEARD_ABOUT_EQ.INSTAGRAM}
        onPress={() => {
          trackEvent('HearAboutUs', 'Instagram');
          handleClick(HEARD_ABOUT_EQ.INSTAGRAM);
        }}
      />
      <OptionButton
        title="Facebook"
        isSelected={quizData.heard_about_eq === HEARD_ABOUT_EQ.FACEBOOK}
        onPress={() => {
          trackEvent('HearAboutUs', 'Facebook');
          handleClick(HEARD_ABOUT_EQ.INSTAGRAM);
        }}
      />
      <OptionButton
        title="TikTok"
        isSelected={quizData.heard_about_eq === HEARD_ABOUT_EQ.TIKTOK}
        onPress={() => {
          trackEvent('HearAboutUs', 'TikTok');
          handleClick(HEARD_ABOUT_EQ.TIKTOK);
        }}
      />
      <OptionButton
        title="Google search"
        isSelected={quizData.heard_about_eq === HEARD_ABOUT_EQ.GOOGLE_SEARCH}
        onPress={() => {
          trackEvent('HearAboutUs', 'Google search');
          handleClick(HEARD_ABOUT_EQ.GOOGLE_SEARCH);
        }}
      />
      <OptionButton
        title="Spotify"
        isSelected={quizData.heard_about_eq === HEARD_ABOUT_EQ.SPOTIFY}
        onPress={() => {
          trackEvent('HearAboutUs', 'Spotify');
          handleClick(HEARD_ABOUT_EQ.SPOTIFY);
        }}
      />
      <OptionButton
        title="I'm a return client"
        isSelected={quizData.heard_about_eq === HEARD_ABOUT_EQ.RETURNING_USER}
        onPress={() => {
          trackEvent('HearAboutUs', "I'm a return client");
          handleClick(HEARD_ABOUT_EQ.RETURNING_USER);
        }}
      />
      <OptionButton
        title="None of the above"
        isSelected={quizData.heard_about_eq === HEARD_ABOUT_EQ.NONE}
        onPress={() => {
          trackEvent('HearAboutUs', 'None of the above');
          handleClick(HEARD_ABOUT_EQ.NONE);
        }}
      />
    </Box>
  );
};

const GeneralSection = () => {
  const { quizData } = useSelector((state: IAppState) => state.quiz);

  if (quizData.currentStep === 26) {
    return (
      <QuizPageWrapper title="Would you be interested in any of the following?">
        <InterestStep />
      </QuizPageWrapper>
    );
  }
  if (quizData.currentStep === 27) {
    return (
      <QuizPageWrapper title="Where are you located?">
        <LocatedStep />
      </QuizPageWrapper>
    );
  }
  if (quizData.currentStep === 28) {
    return (
      <QuizPageWrapper title="How did you hear about us?">
        <HeardAboutStep />
      </QuizPageWrapper>
    );
  }
  return <></>;
};
export default GeneralSection;
