import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Header from '../../../components/header/header';
import styles from './confirmation.module.scss';
import appleBadge from '../../../assets/images/mobile/badge_apple.png';
import androidBadge from '../../../assets/images/mobile/badge_android.png';
import ContentWrapper from '../../../components/content/content-wrapper';
import appScreen from '../../../assets/images/phone-confirmation-3.png';
import ContentSection from '../../../components/content/content-section';
import { IAppState } from '../../../store';
import history from '../../../lib/history';
import { Coupon } from 'src/pages/deprecated/payment/types';
import moment from 'moment';

declare global {
  interface Window {
    ire: any;
  }
}

const dateTime = new Date().toISOString();

const Confirmation = (): JSX.Element => {
  // Redux Selectors
  const user = useSelector((state: IAppState) => state.auth?.user);
  const futureCommencement = (history.location as any).state
    ?.futureCommencement;
  const priceId: number = (history.location as any).state?.priceId;
  const totalPrice = (history.location as any).state.totalPrice;
  const coupon: Coupon = (history.location as any).state?.coupon;
  useEffect(() => {
    (async () => {
      if (!user || !priceId || !totalPrice) {
        return;
      }

      window.ire(
        'trackConversion',
        33152,
        {
          orderId: `${priceId}_${user?.id}_${dateTime}`,
          customerId: user?.id,
          customerEmail: user?.email,
          customerStatus: user.status,
          currencyCode: 'AUD',
          orderPromoCode: coupon?.id || '',
          orderDiscount: ((coupon?.amountOff || 0) / 100).toFixed(2) || '0.00',
          items: [
            {
              sku: priceId,
              name: priceId,
              category: '',
              quantity: 1,
              subTotal: totalPrice || '0.00',
            },
          ],
        },
        {
          verifySiteDefinitionMatch: true,
        }
      );
    })();
  }, [coupon, priceId, totalPrice, user]);

  return (
    <>
      <Header module="login" />

      <div className={styles.confirmation}>
        <ContentWrapper
          className={styles.imageWrapper}
          innerClassName={styles.imageInnerWrapper}>
          <div className={styles.imageFlex}>
            <img src={appScreen} alt="Equalution App Screen" />
          </div>
        </ContentWrapper>
        <ContentWrapper
          className={styles.wrapper}
          innerClassName={styles.innerWrapper}
          collapseAt="md">
          <ContentSection className={styles.card} collapseAt="md">
            <p className={styles.welcome}>
              Welcome to Equalution, {user?.firstName}!
            </p>
            {coupon?.metadata?.futureCommencement &&
            futureCommencement !== undefined ? (
              <h2>Ready to meet the new you?</h2>
            ) : (
              <h2>You&apos;re all set.</h2>
            )}
            {coupon?.metadata?.futureCommencement === 'true' &&
            futureCommencement ? (
              <>
                <p className={styles.download}>
                  You&apos;ll get access to our app on{' '}
                  {moment(coupon.metadata.challengeStartDate)
                    .subtract(7, 'days')
                    .format('D MMMM')}{' '}
                  (yep, that&apos;s an extra free week!) so you&apos;ll have
                  everything you need ready to start the{' '}
                  {coupon?.metadata?.challengeName} Challenge on{' '}
                  {moment(coupon?.metadata?.challengeStartDate).format(
                    'dddd D MMMM'
                  )}
                  .
                </p>
                <p className={styles.download}>
                  Get one step ahead and download the app now!
                </p>
                <p className={styles.download}></p>
              </>
            ) : coupon?.metadata?.futureCommencement === 'true' &&
              !futureCommencement ? (
              <div>
                <p className={styles.download}>
                  You&apos;ve got a head start on your transformation journey.
                </p>
                <p className={styles.download}>
                  Download our app to get started before the
                  {coupon?.metadata?.challengeName} Challenge begins on{' '}
                  {moment(coupon?.metadata?.challengeStartDate).format(
                    'dddd D MMMM'
                  )}
                  .
                </p>
                <p className={styles.download}>Thank us later!</p>
              </div>
            ) : coupon?.metadata?.futureCommencement === 'false' ? (
              <div>
                <p className={styles.download}>
                  Download our app to get started for the New Year New You
                  Challenge!
                </p>
                <p className={styles.download}>
                  Although the challenge commences on{' '}
                  {moment(coupon.metadata.challengeStartDate).format('D MMMM')}{' '}
                  it&apos;s not too late to reach your goals!
                </p>
                <p className={styles.download}>
                  We can&apos;t wait to meet the new you!
                </p>
              </div>
            ) : (
              <p className={styles.download}>
                Download the app and start your transformation journey today!
              </p>
            )}

            <div className={styles.appLinks}>
              <a
                href="https://apps.apple.com/au/app/equalution/id1456085024"
                className={styles.appLink}>
                <img alt="Apple Badge link to Apple Store" src={appleBadge} />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.equalution.equalution"
                className={styles.appLink}>
                <img
                  alt="Android Badge link to Google Play"
                  src={androidBadge}
                />
              </a>
            </div>
          </ContentSection>
        </ContentWrapper>
      </div>
    </>
  );
};

export default Confirmation;
