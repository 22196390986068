import React, { useCallback, useContext, useEffect, useState } from 'react';
import QuizPageWrapper from '../QuizPageWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { Box, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { OptionButton } from 'src/components/option-button/OptionButton';
import {
  BREASTFEEDING_FREQUENCY,
  GOALS,
  GOAL_ADHERENCE_CHALLENGES,
  MAX_HEIGHT_CENTIMETERS,
  MAX_HEIGHT_FEET,
  MAX_HEIGHT_INCH,
  MAX_WEIGHT_KG,
  MAX_WEIGHT_POUND,
  MeasurementType,
} from 'src/pages/constants';
import { updateQuizData } from 'src/store/quiz';
import { CircleButton } from 'src/components/circle-button/CircleButton';
import { NumberInput } from 'src/components/number-input/NumberInput';
import { MobileDatePicker } from '@mui/x-date-pickers';
import {
  calculateAge,
  calculateLBM,
  calculateSuggestedWeight,
  centimetersToFeetAndInches,
  inchesToCentimeters,
  isNumericOnly,
  kiloToPounds,
  poundsToKilograms,
} from 'src/lib/utils';
import { InfoBox } from 'src/components/info-box/InfoBox';
import MeasurementUnitTabs from 'src/components/measurement-unit-tabs';
import { ToastContext } from 'src/components/toast/toast';
import moment from 'moment';
import { useQuizTracking } from '../../hooks';
import colors from 'src/styles/equalution/colors';

const PrimaryGoal = () => {
  const { quizData } = useSelector((state: IAppState) => state.quiz);
  const dispatch = useDispatch();
  const handleClick = (value: number) => {
    dispatch(updateQuizData({ goal_id: value, currentStep: 3 }));
  };

  const trackEvent = useQuizTracking();

  return (
    <Box>
      <OptionButton
        title="Fat loss"
        description="To reduce body fat and focus on retaining energy and muscle mass."
        isSelected={quizData.goal_id === GOALS.fat_loss}
        onPress={() => {
          trackEvent('PrimaryGoal', 'Fat Loss');
          handleClick(GOALS.fat_loss);
        }}
      />
      <OptionButton
        title="Maintain"
        description="To manage existing weight and focus on improving body composition."
        isSelected={quizData.goal_id === GOALS.maintain}
        onPress={() => {
          trackEvent('PrimaryGoal', 'Maintain');
          handleClick(GOALS.maintain);
        }}
      />
      <OptionButton
        title="Muscle gain"
        description="To increase muscle mass. This cannot be done together with fat loss."
        isSelected={quizData.goal_id === GOALS.muscle_gain}
        onPress={() => {
          trackEvent('PrimaryGoal', 'Muscle Gain');
          handleClick(GOALS.muscle_gain);
        }}
      />
    </Box>
  );
};

const MotivateStep = () => {
  const dispatch = useDispatch();
  const { addToast } = useContext(ToastContext);
  const [goalAdhereChallenges, setGoalAdhereChallenges] = React.useState<
    Array<number>
  >([]);

  const trackEvent = useQuizTracking();

  const handleClick = (value: number) => {
    const goalAdhereChallengesCopy = [...goalAdhereChallenges];
    if (!goalAdhereChallengesCopy.includes(value)) {
      if (goalAdhereChallengesCopy.length < 3) {
        goalAdhereChallengesCopy.push(value);
      } else {
        addToast('Your can select upto 3 options.', 'info', 5000);
      }
    } else {
      goalAdhereChallengesCopy.splice(
        goalAdhereChallengesCopy.indexOf(value),
        1
      ); //deleting
    }
    setGoalAdhereChallenges(goalAdhereChallengesCopy);
  };
  const handleSubmit = () => {
    dispatch(
      updateQuizData({
        goal_adherence_challenges: goalAdhereChallenges,
        currentStep: 4,
      })
    );
  };
  return (
    <Box>
      <OptionButton
        title="Improved mood & energy"
        isSelected={goalAdhereChallenges.includes(
          GOAL_ADHERENCE_CHALLENGES.IMPROVED_MOOD_AND_ENERGY
        )}
        onPress={() => {
          trackEvent('Motivation', 'Improved mood & energy');
          handleClick(GOAL_ADHERENCE_CHALLENGES.IMPROVED_MOOD_AND_ENERGY);
        }}
      />
      <OptionButton
        title="Improved athletic performance"
        isSelected={goalAdhereChallenges.includes(
          GOAL_ADHERENCE_CHALLENGES.IMPROVED_ATHLETIC_PERFORMANCE
        )}
        onPress={() => {
          trackEvent('Motivation', 'Improved athletic performance');
          handleClick(GOAL_ADHERENCE_CHALLENGES.IMPROVED_ATHLETIC_PERFORMANCE);
        }}
      />
      <OptionButton
        title="Healthier relationship with food"
        isSelected={goalAdhereChallenges.includes(
          GOAL_ADHERENCE_CHALLENGES.HEALTHIER_RELATIONSHIP_WITH_FOOD
        )}
        onPress={() => {
          trackEvent('Motivation', 'Healthier relationship with food');
          handleClick(
            GOAL_ADHERENCE_CHALLENGES.HEALTHIER_RELATIONSHIP_WITH_FOOD
          );
        }}
      />
      <OptionButton
        title="Better sleep"
        isSelected={goalAdhereChallenges.includes(
          GOAL_ADHERENCE_CHALLENGES.BETTER_SLEEP
        )}
        onPress={() => {
          trackEvent('Motivation', 'Better sleep');
          handleClick(GOAL_ADHERENCE_CHALLENGES.BETTER_SLEEP);
        }}
      />
      <OptionButton
        title="Feel more confident"
        isSelected={goalAdhereChallenges.includes(
          GOAL_ADHERENCE_CHALLENGES.FEEL_MORE_CONFIDENT
        )}
        onPress={() => {
          trackEvent('Motivation', 'Feel more confident');
          handleClick(GOAL_ADHERENCE_CHALLENGES.FEEL_MORE_CONFIDENT);
        }}
      />
      <OptionButton
        title="Improved clothing fit"
        isSelected={goalAdhereChallenges.includes(
          GOAL_ADHERENCE_CHALLENGES.IMPROVED_CLOTHING_FIT
        )}
        onPress={() => {
          trackEvent('Motivation', 'Improved clothing fit');
          handleClick(GOAL_ADHERENCE_CHALLENGES.IMPROVED_CLOTHING_FIT);
        }}
      />
      <OptionButton
        title="Other"
        isSelected={goalAdhereChallenges.includes(
          GOAL_ADHERENCE_CHALLENGES.OTHER
        )}
        onPress={() => {
          trackEvent('Motivation', 'Other');
          handleClick(GOAL_ADHERENCE_CHALLENGES.OTHER);
        }}
      />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'flex-end',
          justifyContent: 'end',
        }}>
        <CircleButton
          onPress={handleSubmit}
          disabled={goalAdhereChallenges.length === 0}
        />
      </Box>
    </Box>
  );
};

const GenderStep = () => {
  const { quizData } = useSelector((state: IAppState) => state.quiz);
  const dispatch = useDispatch();
  const trackEvent = useQuizTracking();

  const handleClick = (value: string) => {
    trackEvent('Gender', value);
    dispatch(
      updateQuizData({ gender: value, currentStep: value === 'FEMALE' ? 5 : 7 })
    );
  };

  // if (quizData.isReturning || user?.status === 'INACTIVE') {
  //   dispatch(updateQuizData({ currentStep: 9 }));
  // }
  // we are not getting gender in get client API

  return (
    <Box>
      <OptionButton
        title="Female"
        isSelected={quizData.gender === 'FEMALE'}
        onPress={() => handleClick('FEMALE')}
      />
      <OptionButton
        title="Male"
        isSelected={quizData.gender === 'MALE'}
        onPress={() => handleClick('MALE')}
      />
    </Box>
  );
};

const BreastFeedingStep = () => {
  const { quizData } = useSelector((state: IAppState) => state.quiz);
  const user = useSelector((state: IAppState) => state.auth?.user);
  const dispatch = useDispatch();
  if (quizData.gender === 'MALE') {
    dispatch(updateQuizData({ currentStep: 4 }));
  }
  const trackEvent = useQuizTracking();

  const handleClick = (value: number) => {
    trackEvent('BreastfeedingOption', value === 1 ? 'Yes' : 'No');
    dispatch(
      updateQuizData({ is_breastfeeding: value, currentStep: value ? 6 : 7 })
    );
  };
  if (quizData.isReturning || user?.status === 'INACTIVE') {
    dispatch(updateQuizData({ currentStep: 9 }));
  }
  return (
    <Box>
      <OptionButton
        title="Yes"
        isSelected={quizData.is_breastfeeding === 1}
        onPress={() => handleClick(1)}
      />
      <OptionButton
        title="No"
        isSelected={quizData.is_breastfeeding === 0}
        onPress={() => handleClick(0)}
      />
    </Box>
  );
};

const BreastFeedingFrequencyStep = () => {
  const { quizData } = useSelector((state: IAppState) => state.quiz);
  const user = useSelector((state: IAppState) => state.auth?.user);
  const dispatch = useDispatch();

  const trackEvent = useQuizTracking();

  const handleClick = (value: number) => {
    dispatch(
      updateQuizData({ breastfeeding_frequency: value, currentStep: 7 })
    );
  };
  if (!quizData.is_breastfeeding) {
    dispatch(updateQuizData({ currentStep: 5 }));
  }
  if (quizData.gender === 'MALE') {
    dispatch(updateQuizData({ currentStep: 4 }));
  }
  if (quizData.isReturning || user?.status === 'INACTIVE') {
    dispatch(updateQuizData({ currentStep: 9 }));
  }
  return (
    <Box>
      <OptionButton
        title="Every 2-4 hours"
        description="Exclusively breastfeeding."
        isSelected={
          quizData.breastfeeding_frequency ===
          BREASTFEEDING_FREQUENCY.EVERY_2_4_HOURS
        }
        onPress={() => {
          trackEvent('BreastfeedingFrequency', 'Every 2-4 hours');
          handleClick(BREASTFEEDING_FREQUENCY.EVERY_2_4_HOURS);
        }}
      />
      <OptionButton
        title="Every 4-5 hours"
        description="Approximately 5-6 times a day."
        isSelected={
          quizData.breastfeeding_frequency ===
          BREASTFEEDING_FREQUENCY.EVERY_4_5_HOURS
        }
        onPress={() => {
          trackEvent('BreastfeedingFrequency', 'Every 4-5 hours');
          handleClick(BREASTFEEDING_FREQUENCY.EVERY_4_5_HOURS);
        }}
      />
      <OptionButton
        title="Every 6-8 hours"
        description="Approximately 3-4 times a day"
        isSelected={
          quizData.breastfeeding_frequency ===
          BREASTFEEDING_FREQUENCY.EVERY_6_8_HOURS
        }
        onPress={() => {
          trackEvent('BreastfeedingFrequency', 'Every 6-8 hours');
          handleClick(BREASTFEEDING_FREQUENCY.EVERY_6_8_HOURS);
        }}
      />
    </Box>
  );
};

const BirthdayStep = () => {
  const { quizData } = useSelector((state: IAppState) => state.quiz);
  const user = useSelector((state: IAppState) => state.auth?.user);
  const dispatch = useDispatch();
  const trackEvent = useQuizTracking();

  const [error, setError] = React.useState<string | null>(null);

  if (quizData.isReturning || user?.status === 'INACTIVE') {
    dispatch(updateQuizData({ currentStep: 9 }));
  }

  const handleClick = (value: string | null) => {
    const age = value ? calculateAge(new Date(value)) : 0;
    if (age < 18) {
      setError(
        'Unfortunately, the Equalution program is not currently designed for those under the age of 18.'
      );
    } else {
      setError(null);
    }
    trackEvent('DOB', { dob: value, age });
    dispatch(updateQuizData({ dob: value }));
  };
  let maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 18);
  const handleSubmit = () => {
    dispatch(
      updateQuizData({
        currentStep: 8,
      })
    );
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Box
          sx={{
            backgroundColor: '#FFF9F2',
            borderRadius: 2,
            padding: '16px 2px 1px 2px',
          }}>
          <MobileDatePicker
            label="Date"
            value={quizData.dob}
            onChange={newValue => {
              handleClick(newValue?.toISOString() ?? null);
            }}
            maxDate={maxDate}
            onOpen={() => {
              if (!quizData.dob) {
                dispatch(updateQuizData({ dob: maxDate }));
              }
            }}
            renderInput={params => (
              <TextField
                sx={{
                  '& fieldset': { border: 'none' },
                  color: colors.SALAD,
                }}
                InputProps={{
                  disableUnderline: true,
                }}
                {...params}
              />
            )}
          />
        </Box>
      </LocalizationProvider>
      {error && (
        <Box sx={{ marginTop: 3 }}>
          <InfoBox title={error} showRedIcon={true} />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'flex-end',
          justifyContent: 'end',
          marginTop: 3,
        }}>
        <CircleButton onPress={handleSubmit} disabled={!quizData.dob} />
      </Box>
    </Box>
  );
};

const HeightStep = () => {
  const { quizData } = useSelector((state: IAppState) => state.quiz);
  const user = useSelector((state: IAppState) => state.auth?.user);
  const dispatch = useDispatch();
  const trackEvent = useQuizTracking();

  const { addToast } = useContext(ToastContext);
  const [selectedUnit, setSelectedUnit] = useState<MeasurementType>(
    MeasurementType.Metric
  );
  const [centimeters, setCentimeters] = useState<string | number | undefined>(
    quizData.height
  );
  const [feet, setFeet] = useState<string | undefined>('');
  const [inches, setInches] = useState<string>('');

  if (quizData.isReturning || user?.status === 'INACTIVE') {
    dispatch(updateQuizData({ currentStep: 3 })); // mostly will be triggered if back pressed
  }

  useEffect(() => {
    if (quizData.height) {
      updateCentimeter(quizData.height);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateCentimeter = useCallback(
    (centimeters: string | number | undefined = 0) => {
      dispatch(
        updateQuizData({
          height: centimeters,
        })
      );

      const res = centimetersToFeetAndInches(
        parseFloat(centimeters.toString())
      );
      if (res.feet) {
        setFeet(res.feet.toString());
      } else {
        setFeet('');
      }
      if (res.inches) {
        setInches(res.inches.toString());
      } else {
        setInches('');
      }
    },
    [dispatch]
  );
  const updateFeetToCentimeter = useCallback(
    (feet: string | undefined, inches: string) => {
      // Convert to metric for our back-end
      let height = inchesToCentimeters(
        (feet ? parseFloat(feet) : 0) * 12 + (inches ? parseFloat(inches) : 0)
      );
      // Round to 1 decimal point
      height = Math.round(height * 10) / 10;
      setCentimeters(height);
      dispatch(
        updateQuizData({
          height: height || '',
        })
      );
    },
    [dispatch]
  );

  const handleSubmit = () => {
    if (selectedUnit === MeasurementType.Metric) {
      if (!centimeters) {
        return false;
      }

      trackEvent('Height', Number(centimeters));
      dispatch(
        updateQuizData({
          height: centimeters,
          currentStep: 9,
        })
      );
    } else {
      // Convert to metric for our back-end
      let height = inchesToCentimeters(
        (feet ? parseFloat(feet) : 0) * 12 + (inches ? parseFloat(inches) : 0)
      );
      // Round to 1 decimal point
      height = Math.round(height * 10) / 10;

      trackEvent('Height', height);
      dispatch(
        updateQuizData({
          height: height || '',
          currentStep: 9,
        })
      );
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <MeasurementUnitTabs
        selected={selectedUnit}
        setSelected={setSelectedUnit}
      />
      {selectedUnit === MeasurementType.Metric ? (
        <NumberInput
          label="centimeters"
          value={quizData.height}
          autoFocus
          onChangeValue={val => {
            if (isNumericOnly(val)) {
              if (val && parseFloat(val) > MAX_HEIGHT_CENTIMETERS) {
                addToast('Trying to add invalid height.', 'danger', 5000);
              } else {
                setCentimeters(val);
                if (!val) {
                  updateCentimeter('');
                } else {
                  updateCentimeter(val);
                }
              }
            }
          }}
        />
      ) : (
        <Box sx={{ display: 'flex' }}>
          <NumberInput
            label="feet"
            value={feet}
            autoFocus
            onChangeValue={val => {
              if (isNumericOnly(val)) {
                if (val && parseFloat(val) > MAX_HEIGHT_FEET) {
                  addToast('Trying to add invalid height.', 'danger', 5000);
                } else {
                  setFeet(val);
                  updateFeetToCentimeter(val, inches);
                }
              }
            }}
          />
          <Box sx={{ marginLeft: '30px' }}>
            <NumberInput
              label="inches"
              value={inches}
              onChangeValue={val => {
                if (isNumericOnly(val)) {
                  if (val && parseFloat(val) > MAX_HEIGHT_INCH) {
                    addToast(
                      "Trying to add invalid value. It shouldn't greater than " +
                        MAX_HEIGHT_INCH,
                      'danger',
                      5000
                    );
                  } else {
                    setInches(val);
                    updateFeetToCentimeter(feet, val);
                  }
                }
              }}
            />
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'flex-end',
          justifyContent: 'end',
          marginTop: 3,
        }}>
        <CircleButton
          onPress={handleSubmit}
          disabled={
            selectedUnit === MeasurementType.Metric
              ? !quizData.height
              : !feet && !inches
          }
        />
      </Box>
    </Box>
  );
};

const WeightStep = () => {
  const { quizData } = useSelector((state: IAppState) => state.quiz);
  const dispatch = useDispatch();
  const trackEvent = useQuizTracking();

  const [selectedUnit, setSelectedUnit] = useState<MeasurementType>(
    MeasurementType.Metric
  );
  const { addToast } = useContext(ToastContext);

  const [kilos, setKilos] = useState<string | undefined>(
    quizData.weight ? quizData.weight.toString() : ''
  );
  const [pounds, setPounds] = useState<string | undefined>(
    kiloToPounds(quizData.weight).toString()
  );

  const updateToPound = (val: number) => {
    if (kiloToPounds(val)) {
      setPounds(kiloToPounds(val).toString());
    } else {
      setPounds('');
    }
  };
  const updateToKg = (val: number) => {
    if (poundsToKilograms(val)) {
      setKilos(poundsToKilograms(val).toString());
    } else {
      setKilos('');
    }
  };

  useEffect(() => {
    if (quizData.weight) {
      updateToPound(quizData.weight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    if (selectedUnit === MeasurementType.Metric) {
      if (!kilos) {
        return false;
      }

      trackEvent('Weight', parseFloat(kilos));
      dispatch(
        updateQuizData({
          weight: parseFloat(kilos),
          currentStep: quizData.goal_id === GOALS.maintain ? 12 : 10,
        })
      );
    } else {
      if (!pounds) {
        return false;
      }
      // Convert to metric for our back-end
      let convertedKilos = poundsToKilograms(parseFloat(pounds));
      // Round to 1 decimal point

      trackEvent('Weight', convertedKilos);
      dispatch(
        updateQuizData({
          weight: convertedKilos,
          currentStep: quizData.goal_id === GOALS.maintain ? 12 : 10,
        })
      );
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <MeasurementUnitTabs
        selected={selectedUnit}
        setSelected={setSelectedUnit}
      />
      {selectedUnit === MeasurementType.Metric ? (
        <NumberInput
          label="kilograms"
          value={kilos}
          autoFocus
          onChangeValue={val => {
            if (isNumericOnly(val)) {
              if (val && parseFloat(val) > MAX_WEIGHT_KG) {
                addToast('Trying to add invalid weight.', 'danger', 5000);
              } else {
                setKilos(val);
                if (!val) {
                  updateToPound(0);
                } else {
                  updateToPound(parseFloat(val));
                }
              }
            }
          }}
        />
      ) : (
        <NumberInput
          label="Pounds"
          value={pounds}
          autoFocus
          onChangeValue={val => {
            if (isNumericOnly(val)) {
              if (val && parseFloat(val) > MAX_WEIGHT_POUND) {
                addToast('Trying to add invalid weight.', 'danger', 5000);
              } else {
                setPounds(val);
                if (!val) {
                  updateToKg(0);
                } else {
                  updateToKg(parseFloat(val));
                }
              }
            }
          }}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'flex-end',
          justifyContent: 'end',
          marginTop: 3,
        }}>
        <CircleButton
          onPress={handleSubmit}
          disabled={selectedUnit === MeasurementType.Metric ? !kilos : !pounds}
        />
      </Box>
    </Box>
  );
};

const HasTargetWeight = () => {
  const { quizData } = useSelector((state: IAppState) => state.quiz);
  const dispatch = useDispatch();
  const trackEvent = useQuizTracking();
  if (quizData.goal_id === GOALS.maintain) {
    dispatch(updateQuizData({ currentStep: 9 }));
  }

  const handleClick = (value: boolean) => {
    trackEvent('TargetWeightOption', value ? 'Yes' : 'No');
    dispatch(
      updateQuizData({ has_target_weight: value, currentStep: value ? 11 : 12 })
    );
  };
  return (
    <Box>
      <OptionButton
        title="Yes"
        isSelected={!!quizData.has_target_weight}
        onPress={() => handleClick(true)}
      />
      <OptionButton
        title="No"
        isSelected={quizData.has_target_weight === false}
        onPress={() => handleClick(false)}
      />
    </Box>
  );
};

const TargetWeightStep = () => {
  const { quizData } = useSelector((state: IAppState) => state.quiz);
  const dispatch = useDispatch();
  const trackEvent = useQuizTracking();

  const [selectedUnit, setSelectedUnit] = useState<MeasurementType>(
    MeasurementType.Metric
  );

  if (quizData.goal_id === GOALS.maintain) {
    dispatch(updateQuizData({ currentStep: 9 })); // mostly will be triggered if back pressed
  }
  if (!quizData.has_target_weight) {
    dispatch(updateQuizData({ currentStep: 10 })); // mostly will be triggered if back pressed
  }

  const [kilos, setKilos] = useState<string>(
    quizData.target_weight ? quizData.target_weight.toString() : ''
  );
  const [pounds, setPounds] = useState<string>(
    quizData.target_weight
      ? kiloToPounds(quizData.target_weight).toString()
      : ''
  );
  const updateToPound = (val: number) => {
    setPounds(kiloToPounds(val).toString());
  };
  const updateToKg = (val: number) => {
    setKilos(poundsToKilograms(val).toString());
  };
  const [error, setError] = useState('');

  const lbmError = (
    dob: string,
    weight: any,
    height: number,
    gender: any,
    targetWeight: number
  ): boolean => {
    if (weight) {
      const lbm = calculateLBM({
        weight: weight,
        height: height,
        gender,
      });
      const age = moment().diff(dob, 'years');

      const suggestedWeight = calculateSuggestedWeight({ gender, age, lbm });

      return targetWeight < suggestedWeight;
    }
    return false;
  };

  const handleCheckingError = useCallback(
    (kilos: number) => {
      if (!kilos) {
        return false;
      }
      if (quizData.goal_id === GOALS.muscle_gain && kilos < quizData.weight) {
        setError(
          'Your target weight cannot be lower than your current weight. On a Muscle Gain goal, you will increase lean muscle mass and overall weight.'
        );
      } else if (quizData.goal_id === GOALS.fat_loss) {
        if (kilos > quizData.weight) {
          setError(
            `Your target weight cannot be higher than your current weight on a Fat Loss goal.`
          );
        } else if (
          lbmError(
            quizData.dob,
            quizData.weight,
            parseFloat(quizData.height.toString()),
            quizData.gender,
            kilos
          )
        ) {
          const genderSpecificMessage =
            quizData.gender === 'FEMALE'
              ? 'average healthy body fat percentage range of 21-36% for females.'
              : 'average healthy body fat percentage range of 18-24% for males.';
          setError(
            `We advise considering an adjustment to your target weight. Currently your target falls below the ${genderSpecificMessage} Increasing your goal weight ensures an attainable and more sustainable approach.`
          );
        } else {
          setError('');
        }
      } else {
        setError('');
      }
    },
    [
      quizData.dob,
      quizData.gender,
      quizData.goal_id,
      quizData.height,
      quizData.weight,
    ]
  );

  useEffect(() => {
    if (kilos) {
      handleCheckingError(parseFloat(kilos));
    } else {
      setError('');
    }
  }, [handleCheckingError, kilos]);

  const handleSubmit = () => {
    if (selectedUnit === MeasurementType.Metric) {
      if (!kilos) {
        return false;
      }
      trackEvent('TargetWeight', kilos);
      dispatch(
        updateQuizData({
          target_weight: kilos,
          currentStep: 12,
        })
      );
    } else {
      if (!pounds) {
        return false;
      }
      // Convert to metric for our back-end
      let convertedKilos = poundsToKilograms(parseFloat(pounds));
      // Round to 1 decimal point

      trackEvent('TargetWeight', convertedKilos);
      dispatch(
        updateQuizData({
          target_weight: convertedKilos,
          currentStep: 12,
        })
      );
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <MeasurementUnitTabs
        selected={selectedUnit}
        setSelected={setSelectedUnit}
      />
      {selectedUnit === MeasurementType.Metric ? (
        <NumberInput
          label="kilograms"
          autoFocus
          value={kilos}
          onChangeValue={val => {
            if (isNumericOnly(val)) {
              setKilos(val);
              updateToPound(parseFloat(val));
            }
          }}
        />
      ) : (
        <NumberInput
          label="Pounds"
          autoFocus
          value={pounds}
          onChangeValue={val => {
            if (isNumericOnly(val)) {
              setPounds(val);
              updateToKg(parseFloat(val));
            }
          }}
        />
      )}

      {error && (
        <Box sx={{ marginTop: 3 }}>
          <InfoBox title={error} showRedIcon />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'flex-end',
          justifyContent: 'end',
          marginTop: 3,
        }}>
        <CircleButton
          onPress={handleSubmit}
          disabled={
            !kilos || (!!error && quizData.goal_id === GOALS.muscle_gain)
          }
        />
      </Box>
    </Box>
  );
};

const ProfileSection = () => {
  const { quizData } = useSelector((state: IAppState) => state.quiz);

  if (quizData.currentStep === 2) {
    return (
      <QuizPageWrapper
        title="What is your primary goal?"
        subTitle="So we can get there together.">
        <PrimaryGoal />
      </QuizPageWrapper>
    );
  }
  if (quizData.currentStep === 3) {
    return (
      <QuizPageWrapper
        title="What other reasons motivate you to improve your nutrition?"
        subTitle="Select up to 3 to start with.">
        <MotivateStep />
      </QuizPageWrapper>
    );
  }
  if (quizData.currentStep === 4) {
    return (
      <QuizPageWrapper
        title="What’s your assigned sex at birth?"
        subTitle="So we can calculate your body’s needs.">
        <GenderStep />
      </QuizPageWrapper>
    );
  }
  if (quizData.currentStep === 5) {
    return (
      <QuizPageWrapper
        title="Are you currently breastfeeding?"
        subTitle="So we can calculate your body’s needs.">
        <BreastFeedingStep />
      </QuizPageWrapper>
    );
  }
  if (quizData.currentStep === 6) {
    return (
      <QuizPageWrapper
        title="How often are you breastfeeding?"
        subTitle="We calculate your calories in line with breastfeeding to maintain your energy and milk supply.">
        <BreastFeedingFrequencyStep />
      </QuizPageWrapper>
    );
  }
  if (quizData.currentStep === 7) {
    return (
      <QuizPageWrapper
        title="When is your birthday?"
        subTitle="So we can calculate your body’s needs.">
        <BirthdayStep />
      </QuizPageWrapper>
    );
  }
  if (quizData.currentStep === 8) {
    return (
      <QuizPageWrapper
        title="How tall are you?"
        subTitle="So we can calculate your body’s needs.">
        <HeightStep />
      </QuizPageWrapper>
    );
  }
  if (quizData.currentStep === 9) {
    return (
      <QuizPageWrapper
        title="What is your weight?"
        subTitle="So we can calculate your body’s needs.">
        <WeightStep />
      </QuizPageWrapper>
    );
  }
  if (quizData.currentStep === 10) {
    return (
      <QuizPageWrapper
        title="Do you have a target weight in mind? (optional)"
        subTitle="We value a holistic approach beyond weight as the primary measure of progress.">
        <HasTargetWeight />
      </QuizPageWrapper>
    );
  }
  if (quizData.currentStep === 11) {
    return (
      <QuizPageWrapper
        title="Could you share your target weight? (optional)"
        subTitle="So we know what you’re working towards.">
        <TargetWeightStep />
      </QuizPageWrapper>
    );
  }
  return <></>;
};
export default ProfileSection;
